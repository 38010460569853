import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// Mui imports
import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
// File imports
import { LARGE_SPACING } from 'constants'
import { MEDIUM_SPACING } from 'constants'

const Container = styled(Grid)`
  margin-bottom: ${LARGE_SPACING};
`

const LeftItem = styled(Grid)`
  padding-right: ${LARGE_SPACING};
  border-radius: 20px;
`

const RightItem = styled(Grid)`
  padding-left: ${LARGE_SPACING};
`

const TitleContainer = styled(Grid)`
  padding-bottom: ${MEDIUM_SPACING};
`

const DateCreatedBlock = styled(Typography)`
  padding-left: ${MEDIUM_SPACING};
`

const ReadMoreContainer = styled.div`
  float: right;
  margin-top: ${MEDIUM_SPACING}
`

const Loading = () => {
  return (
    <Container container>
      <LeftItem item xs={6}>
        <Skeleton
          height={300}
          borderRadius={20}
        />
      </LeftItem>
      <RightItem item xs={6}>
        <TitleContainer container alignItems='baseline'>
          <Grid item xs={6}>
            <Typography
              variant='h4'
              display='inline'
            >
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <DateCreatedBlock display='inline'>
              <Skeleton />
            </DateCreatedBlock>
          </Grid>
        </TitleContainer>
        <Typography>
          <Skeleton count={5} />
        </Typography>
        <ReadMoreContainer>
          <Typography
            variant='p'
            display='inline'
          >
            <Skeleton width={100} />
          </Typography>
        </ReadMoreContainer>
      </RightItem>
    </Container>
  )
}

export default Loading
