import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { collection, getDocs } from 'firebase/firestore'
import 'firebase/firestore'
import { auth, db } from 'firebase-config'
import { useDispatch } from 'react-redux'
import './App.css'

import AdminHome from './pages/Admin'
import Home from './pages/Home'
import Login from './pages/Login'
import BlogHome from 'pages/BlogHome'
import BlogPostPage from 'pages/BlogPostPage'

import TopNav from './components/TopNav'

import { updateAllPosts } from 'redux/reducers/postsSlice'

const App = () => {
  const dispatch = useDispatch()
  const postsCollectionsRef = collection(db, 'posts')
  const [isAuth, setIsAuth] = useState(localStorage.getItem('isAuth'))


  useEffect(() => {
    const getPosts = async () => {
      const data = await getDocs(postsCollectionsRef)
      const posts = data.docs.reduce((acc, doc) => {
        acc[doc.id] = {
          ...doc.data(),
          // id: doc.data().url
        }
        return acc
      }, {})
      dispatch(updateAllPosts(posts))
    }
    getPosts()
  }, [])

  return (
    <Router>
      <TopNav auth={auth} isAuth={isAuth} setIsAuth={setIsAuth} />
      <Routes>
        <Route path='/' element={<Home isAuth={isAuth} />} />
        <Route path='/admin' element={<AdminHome />} />
        <Route path='/admin-login/4lbemxctwo' element={<Login setIsAuth={setIsAuth} />} />
        <Route path='/blog' element={<BlogHome isAuth={isAuth} />} />
        <Route path='/blog/:id' element={<BlogPostPage />} />
      </Routes>
    </Router>
  );
}

export default App
