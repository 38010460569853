import React from 'react'
import { useNavigate } from 'react-router-dom'
// Firebase imports
import { auth, provider } from 'firebase-config'
import { signInWithPopup } from 'firebase/auth'
// MUI Imports
import styled from '@emotion/styled'
import { Button, Grid, Typography } from '@mui/material';
// File Imports
import PageContainer from 'components-layout/PageContainer';
import TitleContainer from 'components-layout/TitleContainer';
import { StyledButton } from 'components/basic-styled-components';
import { LARGE_SPACING } from 'constants'

const ButtonContainer = styled(Grid)`
  padding-left: ${LARGE_SPACING};
  padding-right: ${LARGE_SPACING};
`

const Login = ({ setIsAuth }) => {
  
  let navigate = useNavigate()
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((res) => {
        console.log(res)
        localStorage.setItem('isAuth', true)
        setIsAuth(true)
        navigate('/')
      })
  }

  return (
    <PageContainer>
      <TitleContainer align='center'>
        Admin Log In
      </TitleContainer>
      <ButtonContainer>
        <StyledButton onClick={signInWithGoogle} variant='contained' fullWidth>
          Sign in with Google
        </StyledButton>
      </ButtonContainer>
    </PageContainer>
  );
}

export default Login
