// GLOBAL MARGINS
export const SMALL_SPACING = '8px'
export const MEDIUM_SPACING = '16px'
export const LARGE_SPACING = '32px'
export const HUGE_SPACING = '64px'

// SCREEN WIDTHS
export const MAX_SCREEN_WIDTH_SMALL = '600px'
export const MAX_SCREEN_WIDTH = '1000px'
export const MAX_SCREEN_WIDTH_WIDE = '1200px'
