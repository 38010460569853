import React, { useState } from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { Grid, Skeleton, Typography } from '@mui/material'

import { MEDIUM_SPACING, SMALL_SPACING } from 'constants'
import { StyledImgMaxFit } from 'components/basic-styled-components'
import theme from 'theme'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  background-image: ${props => props.background};
  height: 100%;
  border-radius: 20px;
  padding: ${MEDIUM_SPACING};
  box-sizing: border-box;
  cursor: pointer;
  transition: box-shadow .3s;
  &:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  }
`

const RightInsideItem = styled(Grid)`
  padding-left: ${MEDIUM_SPACING};
`

const DateContainer = styled.div`
  background-color: ${props => (props.variant === 'light') ?
    'white' :
    theme.palette.neutral.main};
  display: inline-block;
  padding: ${SMALL_SPACING} ${MEDIUM_SPACING};
  border-radius: 20px;
  overflow: hidden;
`
const TitleContainer = styled.div`
  padding-top: ${SMALL_SPACING};
  padding-bottom: ${SMALL_SPACING};
  overflow: hidden;
`

const Title = styled(Typography)`
  font-weight: bold;
  color: ${props => props.isLight && 'white'};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3; 
  -webkit-box-orient: vertical;
`

const DateText = styled(Typography)`
  color: ${props => !props.isLight && 'white'};
  font-size: 0.8rem;
`

const CustomHorizontalRule = styled.hr`
  height: 4px;
  background-color: ${props => props.isLight ? 'white' : theme.palette.neutral.main};
  border: none;
  border-radius: 10px;
`

const HRContainer = styled.div`
  width: 50%;
  margin-bottom: ${MEDIUM_SPACING};
`

const SummaryTypography = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3; 
  -webkit-box-orient: vertical;
`

const SideBlog = ({
  background,
  variant,
  blogDetails,
}) => {
  const storage = getStorage()
  const navigate = useNavigate()
  const [imgUrl, setImgUrl] = useState(null)

  const isLight = (variant === 'light')
  const loading = !blogDetails

  if (!loading && blogDetails.mainPhotoPath) {
    const pathReference = ref(storage, blogDetails.mainPhotoPath)
    getDownloadURL(pathReference).then((url) => {
      setImgUrl(url)
    })
  }

  const handleClick = () => {
    navigate(`/blog/${blogDetails.url}`)
  }

  return (
    <Container onClick={handleClick} background={background}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={6}>
          {imgUrl ? (
            <StyledImgMaxFit
              src={imgUrl}
              style={{
                border: `1px solid ${isLight ? 'white' : theme.palette.neutral.main}`,
              }}
            />
          ) : (
            <Skeleton
              height='100%'
              variant='rectangular'
              style={{
                border: `1px solid ${isLight ? 'white' : theme.palette.neutral.main}`,
                borderRadius: 20,
              }}
            />
          )}
        </Grid>
        {!loading && (
          <RightInsideItem item xs={6}>
            <DateContainer variant={variant}>
              <DateText isLight={isLight}>
                {dayjs(blogDetails.dateCreated).format('MMMM D, YYYY')}
              </DateText>
            </DateContainer>
            <TitleContainer>
              <Title variant='h3' isLight={isLight}>
                {blogDetails.title}
              </Title>
            </TitleContainer>
            <HRContainer>
              <CustomHorizontalRule isLight={isLight} />
            </HRContainer>
            <SummaryTypography color={isLight ? 'white' : ''}>
              {blogDetails.firstParagraphText}
            </SummaryTypography>
          </RightInsideItem>
        )}
      </Grid>
    </Container>
  )
}

export default SideBlog
