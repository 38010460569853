import React from 'react'
import { Grid } from '@mui/material'
import styled from '@emotion/styled'

import { LARGE_SPACING, MAX_SCREEN_WIDTH, MAX_SCREEN_WIDTH_SMALL } from 'constants'

const OuterContainer = styled.div`
  margin-left: ${LARGE_SPACING};
  margin-right: ${LARGE_SPACING};
  width: ${MAX_SCREEN_WIDTH};
`

const PageContainer = ({ children, width }) => {
  return (
    <Grid container justifyContent='center'>
      <OuterContainer width={width}>
        {children}
      </OuterContainer>
    </Grid>
  )
}

export default PageContainer
