import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { signOut } from 'firebase/auth'
import styled from '@emotion/styled'
import { Grid, Typography, Tabs, Tab } from '@mui/material'
import theme from 'theme'
import { LARGE_SPACING, MEDIUM_SPACING } from 'constants'
import { StyledButton } from './basic-styled-components'
import { useSelector } from 'react-redux'
import { MAX_SCREEN_WIDTH_WIDE } from 'constants'

const NavContainer = styled(Grid)`
  padding-right: ${LARGE_SPACING};
  padding-left: ${LARGE_SPACING};
  padding-top: ${MEDIUM_SPACING};
  padding-bottom: ${MEDIUM_SPACING};
  border-bottom: 1px solid ${theme.palette.neutral.main};
`

const OuterContainer = styled.div`
  margin-bottom: ${LARGE_SPACING};
`

const InnerGrid = styled(Grid)`
  max-width: ${MAX_SCREEN_WIDTH_WIDE};
`

const MaxHeightGrid = styled(Grid)`
  height: 100%;
`

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    height: '100%',
    textTransform: 'none',
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
    color: theme.typography.allVariants.color,
    '&.Mui-selected': {
      color: theme.typography.allVariants.color,
      backgroundColor: theme.palette.neutral.medium,
      borderRadius: 20,
      opacity: 1,
    },
    '&:hover': {
      opacity: 1,
    }
  }),
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  height: '100%',
  '& .MuiTabs-flexContainer': {
    height: '100%',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
});

const TopNav = ({ auth, isAuth, setIsAuth, props }) => {
  const navigate = useNavigate()

  const { selectedPage } = useSelector((state) => {
    return {
      selectedPage: state.layout.selectedPage,
    }
  })

  const [selectedTab, setSelectedTab] = useState(selectedPage && selectedPage.index)

  const signUserOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.clear()
        setIsAuth(false)
        navigate('/login')
      })
  }

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate('/')
        break
      case 1:
        navigate('/blog')
        break
      default:
        break
    }
    setSelectedTab(newValue)
  }

  const handleChangeAdmin = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate('/')
        break
      case 1:
        navigate('/blog')
        break
      case 2:
        navigate('/admin')
        break
      default:
        break
    }
    setSelectedTab(newValue)
  }

  useEffect(() => {
    if (selectedPage?.index >= 0) {
      if (isAuth) {
        handleChangeAdmin(undefined, selectedPage.index)
      } else {
        handleChange(undefined, selectedPage.index)
      }

    } else {
      // We are on a page that doesn't need a tab selected
      setSelectedTab(false)
    }
  }, [selectedPage])

  return (
    <OuterContainer>
      <NavContainer container justifyContent='center'>
        <InnerGrid
          container
          justifyContent='space-between'
          alignContent='center'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='h1'>
              ER
            </Typography>
          </Grid>
          <MaxHeightGrid item>
            <StyledTabs value={selectedTab} onChange={isAuth ? handleChangeAdmin : handleChange}>
              <StyledTab label='Home'/>
              <StyledTab label='Blog' />
              {isAuth && (
                <StyledTab label='Admin'/>
              )}
            </StyledTabs>
          </MaxHeightGrid>
          {/* Keep this div for alignment within the grid */}
          <Grid item>
            {isAuth ? (
              <StyledButton
                onClick={signUserOut}
                variant='contained'
                disableElevation
              >
                Log Out
              </StyledButton>
            ) : (
              null
              // <StyledButton
              //   onClick={() => {
              //     navigate('/login')
              //   }}
              //   variant='contained'
              //   disableElevation
              // >
              //   Log In
              // </StyledButton>
            )}
          </Grid>
        </InnerGrid>
      </NavContainer>
    </OuterContainer>
  )
}

export default TopNav
