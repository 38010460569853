import React, { useState } from 'react'
import dayjs from 'dayjs'
import theme from 'theme'

import styled from '@emotion/styled'
import { Grid, Skeleton, Typography } from '@mui/material'

import { LARGE_SPACING, MEDIUM_SPACING, SMALL_SPACING } from 'constants'
import { StyledImg, StyledImgMaxFit } from 'components/basic-styled-components'
import HalfMarathonPic from 'static-images/half-marathon.png'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  background-color: #FFF975;
  height: 100%;
  border-radius: 20px;
  padding: ${LARGE_SPACING};
  box-sizing: border-box;
  cursor: pointer;
  transition: box-shadow .3s;
  &:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  }
`

const DateContainer = styled.div`
  background-color: ${theme.palette.neutral.main};
  display: inline-block;
  padding: ${SMALL_SPACING} ${MEDIUM_SPACING};
  border-radius: 20px;
`

const RightInsideItem = styled(Grid)`
  padding-left: ${LARGE_SPACING};
`

const TitleContainer = styled.div`
  padding-top: ${LARGE_SPACING};
  padding-bottom: ${LARGE_SPACING};
  overflow: hidden;
`

const Title = styled(Typography)`
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3; 
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const FirstParagraph = styled(Typography)`
  margin-bottom: ${LARGE_SPACING};
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8; 
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const CustomHorizontalRule = styled.hr`
  height: 4px;
  background-color: ${theme.palette.neutral.main};
  border: none;
  border-radius: 10px;
`

const HRContainer = styled.div`
  width: 50%;
  margin-bottom: ${LARGE_SPACING};
`

const BigBlog = ({
  blogDetails,
}) => {
  const storage = getStorage()
  const navigate = useNavigate()
  const [imgUrl, setImgUrl] = useState(null)

  const loading = !blogDetails

  if (!loading && blogDetails.mainPhotoPath) {
    const pathReference = ref(storage, blogDetails.mainPhotoPath)
    getDownloadURL(pathReference).then((url) => {
      setImgUrl(url)
    })
  }

  const handleClick = () => {
    navigate(`/blog/${blogDetails.url}`)
  }

  return (
    <Container onClick={handleClick}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={6}>
          {imgUrl ? (
            <StyledImgMaxFit
              src={imgUrl}
            />
          ) : (
            <Skeleton
              height='100%'
              variant='rectangular'
              style={{
                borderRadius: 20,
              }}
            />
          )}
        </Grid>
        <RightInsideItem item xs={6}>
          {!loading && (
            <>
              <DateContainer>
                <Typography color='white'>
                  {dayjs(blogDetails.dateCreated).format('MMMM D, YYYY')}
                </Typography>
              </DateContainer>
              <TitleContainer>
                <Title variant='h2'>
                  {blogDetails.title}
                </Title>
              </TitleContainer>
              <HRContainer>
                <CustomHorizontalRule />
              </HRContainer>
              <FirstParagraph>
                {blogDetails.firstParagraphText}
              </FirstParagraph>
            </>
          )}
        </RightInsideItem>
      </Grid>
    </Container>
  )
}

export default BigBlog
