import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Grid } from '@mui/material'

import BlogFullPage from 'components/BlogPost/BlogFullPage'
import { MAX_SCREEN_WIDTH_SMALL, LARGE_SPACING } from 'constants'
import { updateSelectedPage } from 'redux/reducers/layoutSlice'

import styled from '@emotion/styled'
import Footer from 'components/Footer'
import { Helmet } from 'react-helmet'

const OuterContainer = styled.div`
  margin-left: ${LARGE_SPACING};
  margin-right: ${LARGE_SPACING};
  width: 800px;
`

const BlogPostPage = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { posts } = useSelector((state) => {
    return {
      posts: state.posts.allPosts,
    }
  })

  useEffect(() => {
    dispatch(updateSelectedPage(false))
  }, [])

  const selectedPostIdArr = Object.keys(posts).filter((postKey) => {
    return posts[postKey].url === id
  })

  const postExists = selectedPostIdArr && (selectedPostIdArr.length === 1)

  const postId = selectedPostIdArr[0]
  const postDetails = posts[postId]

  if (postExists) {
    return (
      <>
        <Helmet>
          <title>{postDetails.title}</title>
          <meta name="description" content={postDetails.firstParagraphText} />
        </Helmet>
        <Grid container justifyContent='center'>
          <OuterContainer>
            <BlogFullPage
              postId={postId}
              postDetails={postDetails}
            />
          </OuterContainer>
        </Grid>
        <Footer />
      </>
    )
  }

  // Add loading here

}

export default BlogPostPage
