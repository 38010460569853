import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allPosts: {},
}

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    updateAllPosts: (state, action) => {
      const incomingPosts = action.payload
      state.allPosts = incomingPosts
    },
  }
})

export const { updateAllPosts } = postsSlice.actions

export default postsSlice.reducer