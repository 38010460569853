import React from 'react';
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client';
import App from './App';

import firebase from 'firebase/compat/app'
// import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

// import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles'

import theme from './theme'
import store from 'redux/store'

const root = ReactDOM.createRoot(document.getElementById('root'))
const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
}

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      {/* <ReactReduxFirebaseProvider {...rrfProps}> */}
        <React.StrictMode>
          <App />
        </React.StrictMode>
      {/* </ReactReduxFirebaseProvider> */}
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
