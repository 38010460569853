// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHMQvnJIg3b62vB-rqYcMs9YamvpYbKcc",
  authDomain: "ericsremote-dfc14.firebaseapp.com",
  projectId: "ericsremote-dfc14",
  storageBucket: "ericsremote-dfc14.appspot.com",
  messagingSenderId: "576221341437",
  appId: "1:576221341437:web:dfd0bd492d7632e3e3c094",
  measurementId: "G-BJ7C4E824W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const db = getFirestore(app)
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider()
