import { createSlice } from '@reduxjs/toolkit'

// const homePage = {
//   name: 'home',
//   index: 0,
// }
const initialState = {
  // selectedPage: homePage,
  selectedPage: false,
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialState,
  reducers: {
    updateSelectedPage(state, action) {
      const selectedPage = action.payload
      state.selectedPage = selectedPage
    },
  },
})

export const { updateSelectedPage } = layoutSlice.actions

export default layoutSlice.reducer