import React from 'react'

import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'

import { CustomHorizontalRule } from 'components/basic-styled-components'

const TitleText = styled(Typography)`
  font-weight: bold;
`

const TitleContainer = ({
  children,
  align,
}) => {

  return (
    <>
      <Grid container justifyContent={align}>
        <TitleText variant='h2'>
          {children}
        </TitleText>
      </Grid>
      <CustomHorizontalRule />
    </>
  )
}

export default TitleContainer
