import React from 'react'

import styled from '@emotion/styled'

import theme from 'theme'
import { LARGE_SPACING } from 'constants'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

export const CustomHorizontalRule = styled.hr`
  height: 2px;
  background-color: ${theme.palette.neutral.main};
  border: none;
  margin-top: ${props => props.spacing || LARGE_SPACING};
  margin-bottom: ${props => props.spacing || LARGE_SPACING};
`

export const StyledButton = styled(Button)`
  border-radius: 25px;
  text-transform: none;
  padding-top: 8px;
  padding-bottom: 8px;
`

export const StyledImg = styled.img`
  border-radius: 20px;
  width: 100%;
  object-fit: cover;
`

export const StyledImgMaxFit = styled.img`
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const StyledModal = ({
  title,
  open,
  text,
  onClose,
  onAccept
}) => {
  const ModalStyles = {
    // padding: '24px 32px 24px 32px',
    width: 550,
    borderRadius: 25,
    border: `2px solid ${theme.palette.neutral.main}`,
  }

  const StyledTitle = styled(DialogTitle)`
    font-weight: bold;
  `

  const StyledActions = styled(DialogActions)`
    padding: 8px 24px 24px 24px;
  `

  return (
    <Dialog open={open} PaperProps={{ style: ModalStyles }} onClose={onClose}>
      <StyledTitle>
        {title}
      </StyledTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      {(onClose || onAccept) && (
        <StyledActions>
          {onClose && (
            <StyledButton onClick={onClose}>
              Cancel
            </StyledButton>
          )}
          {onAccept && (
            <StyledButton variant='contained'  onClick={onAccept}>
              Confirm
            </StyledButton>
          )}
        </StyledActions>
      )}
    </Dialog>
  )
}