import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
// Firebase imports
import { addDoc, collection } from 'firebase/firestore'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import { auth, db } from '../firebase-config'
// Mui imports
import { Button, Chip, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@mui/material'
import styled from '@emotion/styled'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SegmentIcon from '@mui/icons-material/Segment'
import TitleIcon from '@mui/icons-material/Title'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import DeleteIcon from '@mui/icons-material/Delete'
// File imports
import PageContainer from 'components-layout/PageContainer'
import { CustomHorizontalRule, StyledButton } from 'components/basic-styled-components'
import { useDispatch } from 'react-redux'
import { updateSelectedPage } from 'redux/reducers/layoutSlice'
import TitleContainer from 'components-layout/TitleContainer'
import { LARGE_SPACING } from 'constants'
import theme from 'theme'
import { SMALL_SPACING } from 'constants'

export const MenuButton = styled(Button)`
  border-radius: 10px;
  text-transform: none;
  padding-top: 8px;
  padding-bottom: 8px;
`

const BottomButtonContainer = styled(Grid)`
  margin-top: ${LARGE_SPACING};
  padding: 0px ${SMALL_SPACING};
`

const AddFileButtonContainer = styled(Grid)`
  margin-top: ${LARGE_SPACING};
  margin-bottom: 26px; // This needs to be less than the margin on top by 8px
`

const SelectedPhotoChip = styled(Chip)`
  height: 100%;
  width: 100%;
`

const ErrorText = styled(Typography)`
  margin-top: ${LARGE_SPACING};
  margin-bottom: ${LARGE_SPACING};
  color: ${theme.palette.error.main}
`

const ImageContainer = styled(Grid)`
  border: 1px solid blue;
  border-radius: 20px;
  padding: 10px;
  margin: 16px 0px 8px 0px;
  background: linear-gradient(to right, #5350FF, #9D50FF);
`

const AdminHome = ({ setIsAuth }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSelectedPage({
      name: 'admin',
      index: 2,
    }))
  }, [])

  // Blog Post Fields
  const [title, setTitle] = useState('')
  const [firstParagraphText, setFirstParagraphText] = useState('')
  const [photo, setPhoto] = useState(null)
  const [postSections, setPostSections] = useState([])
  const [imageNumber, setImageNumber] = useState(0)
  const dateCreated = dayjs()
  // Other states
  const [photoSelected, setPhotoSelected] = useState(false)
  const [error, setError] = useState(null)
  // Other hooks
  const postsCollectionsRef = collection(db, 'posts')
  const navigate = useNavigate()
  const storage = getStorage()

  const createErrorMessage = () => {
    let message = 'Missing Field: '
    if (!title) {
      return message = message.concat(' Title')
    }
    if (!firstParagraphText) {
      return message = message.concat(' First Paragraph Text')
    }
    if (!photo) {
      return message = message.concat(' Photo')
    }
    return message
  }
  // Checks for errors, uploads photo, and uploads post to firebase
  const createPost = async () => {
    if (!title || !firstParagraphText || !photo) {
      setError(createErrorMessage())
      return
    }
    const imagePath = `images/blog/main/${photo.name + '-' + v4()}`
    const storageRef = ref(storage, imagePath);
    const postDetails = {
      title,
      url: title.toLowerCase().replace(/\s/g, '-'),
      firstParagraphText,
      postSections,
      dateCreated: dateCreated.format(),
      mainPhotoPath: imagePath,
      author: {
        name: auth.currentUser.displayName,
        id: auth.currentUser.uid
      }
    }
    // Upload photo to storage
    await uploadBytes(storageRef, photo)
      .then((snapshot) => {
        console.log('uploaded file: ', snapshot)
      })
      .catch((err) => {
        console.log('ERROR UPLOADING BYTES: ', err)
      })
    // Upload full blog post to firestore
    await addDoc(postsCollectionsRef, postDetails)
      .then(() => {
        console.log('SUCCESS: ', postDetails)
        navigate('/')
      })
      .catch((err) => {
        console.log('ERROR: ', err)
      })
  }

  const onTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const onFirstParagraphChange = (e) => {
    setFirstParagraphText(e.target.value)
  }

  const handleSelectPhoto = (e) => {
    if (!e.target.files) {
      return
    }
    setPhotoSelected(true)
    setPhoto(e.target.files[0])
  }

  const handleRemovePhoto = () => {
    setPhotoSelected(false)
    setPhoto(null)
  }
  // Generic add function that adds section based on type
  const handleAddSection = ({ type, value }) => {
    setPostSections(postSections.concat({
      type,
      value,
    }))
  }
  // Function to remove section
  const handleRemoveSection = (index) => {
    const newSectionsArray = [...postSections]
    newSectionsArray.splice(index, 1)
    setPostSections(newSectionsArray)
  }
  // Generic update function to update section fields
  const handleUpdatePostSection = (e, index) => {
    const postDataCopy = [ ...postSections ]
    postDataCopy[index].value = e.target.value
    setPostSections(postDataCopy)
  }

  const renderPostSection = (post, index) => {
    // Variable for section object
    let section = null
    // Switch statement should hold all section options
    switch (post.type) {
      case 'paragraph':
        section = (
          <Grid item xs={12} key={index}>
            <TextField
              label='Paragraph'
              value={post.value}
              onChange={(e) => handleUpdatePostSection(e, index)}
              margin='normal'
              multiline
              minRows={3}
              fullWidth
              variant='filled'
            />
          </Grid>
        )
        break
      case 'header':
        section = (
          <Grid item xs={12} key={index}>
            <TextField
              label='Header'
              value={post.value}
              onChange={(e) => handleUpdatePostSection(e, index)}
              margin='normal'
              fullWidth
              variant='filled'
            />
          </Grid>
        )
        break
      case 'subheader':
        section = (
          <Grid item xs={12} key={index}>
            <TextField
              label='Subheader'
              value={post.value}
              onChange={(e) => handleUpdatePostSection(e, index)}
              margin='normal'
              fullWidth
              variant='filled'
            />
          </Grid>
        )
        break
      case 'image':
        section = (
          <Grid item xs={12} key={index}>
            <ImageContainer container alignItems='center'>
              <InsertPhotoIcon sx={{ paddingRight: 1, color: 'white' }} />
              <Typography display='inline' color='white'>
                {`Image_placeholder_${post.value}`}
              </Typography>
            </ImageContainer>
          </Grid>
        )
        break
      default:
        section = (
          <Grid item xs={12} key={index}>
            <Typography>
              {`Unknown Section: ${post.type}`}
            </Typography>
          </Grid>
        )
    }

    return (
      <Grid container alignItems='center'>
        <Grid item xs>
          {section}
        </Grid>
        <Grid item sx={{ paddingLeft: SMALL_SPACING }}>
          <IconButton onClick={() => handleRemoveSection(index)}>
            <DeleteIcon color={theme.palette.neutral.main} />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <PageContainer>
      <TitleContainer>
        Create Post
      </TitleContainer>
      <Grid container>
        {/* Title field */}
        <Grid item xs={6}>
          <TextField
            label='Title'
            value={title}
            onChange={onTitleChange}
            fullWidth
            margin='normal'
            variant='filled'
          />
        </Grid>
        {/* Date created field */}
        <Grid item xs={6} sx={{ paddingLeft: LARGE_SPACING }}>
          <TextField
            label='Date Created'
            value={dateCreated.format('MMM D, YYYY')}
            fullWidth
            margin='normal'
            disabled
          />
        </Grid>
        {/* Add main photo button */}
        <AddFileButtonContainer item xs={6}>
          <StyledButton
            component='label'
            variant='outlined'
            disableElevation
            startIcon={<AddPhotoAlternateIcon color='primary' />}
            disabled={photoSelected}
            fullWidth
          >
            Upload Photo
            <input type='file' hidden onChange={handleSelectPhoto}/>
          </StyledButton>
        </AddFileButtonContainer>
        {/* Photo preview */}
        <AddFileButtonContainer item xs={6}>
          {photo && photo.name && (
            <SelectedPhotoChip
              label={photo.name}
              color='secondary'
              onDelete={handleRemovePhoto}
            />
          )}
        </AddFileButtonContainer>
        {/* Main top paragraph section */}
        <Grid item xs={12}>
          <TextField
            label='Introductory Paragraph'
            value={firstParagraphText}
            onChange={onFirstParagraphChange}
            margin='normal'
            multiline
            minRows={3}
            fullWidth
            variant='filled'
          />
        </Grid>
        {/* Additional post sections */}
        {postSections.map((post, i) => {
          return renderPostSection(post, i)
        })}
      </Grid>
      {/* Add Sections */}
      <Grid container>
        <BottomButtonContainer item xs={3}>
          <StyledButton
            variant='outlined'
            disableElevation
            onClick={() => handleAddSection({ type: 'paragraph', value: '' })}
            fullWidth
            startIcon={<SegmentIcon />}
          >
            Add Paragraph
          </StyledButton>
        </BottomButtonContainer>
        <BottomButtonContainer item xs={3}>
          <StyledButton
            variant='outlined'
            disableElevation
            onClick={() => handleAddSection({ type: 'header', value: '' })}
            fullWidth
            startIcon={<TitleIcon />}
          >
            Add Header
          </StyledButton>
        </BottomButtonContainer>
        <BottomButtonContainer item xs={3}>
          <StyledButton
            variant='outlined'
            disableElevation
            onClick={() => handleAddSection({ type: 'subheader', value: '' })}
            fullWidth
            startIcon={<TextFieldsIcon />}
          >
            Add Subheader
          </StyledButton>
        </BottomButtonContainer>
        <BottomButtonContainer item xs={3}>
          <StyledButton
            variant='outlined'
            disableElevation
            onClick={() => {
              handleAddSection({ type: 'image', value: imageNumber })
              const newIndex = imageNumber + 1
              setImageNumber(newIndex)
            }}
            fullWidth
            startIcon={<InsertPhotoIcon />}
          >
            Add Image
          </StyledButton>
        </BottomButtonContainer>
      </Grid>
      <BottomButtonContainer item xs={12}>
        <StyledButton
          variant='contained'
          disableElevation
          onClick={createPost}
          fullWidth
        >
          Submit Post
        </StyledButton>
      </BottomButtonContainer>
      {error && (
        <ErrorText>
          {error}
        </ErrorText>
      )}
    </PageContainer>
  )
}

export default AdminHome
