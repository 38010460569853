import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import dayjs from 'dayjs'
import 'react-loading-skeleton/dist/skeleton.css'
// Firebase imports
import { auth, db } from 'firebase-config'
import { getStorage, ref, getBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { deleteDoc, doc } from 'firebase/firestore'
// Mui imports
import { Button, Grid, IconButton, Link, Typography } from '@mui/material'
import styled from '@emotion/styled'
import DeleteIcon from '@mui/icons-material/Delete'
// File imports
import theme from 'theme'
import { LARGE_SPACING } from 'constants'
import testPhoto from 'static-images/test.jpg'
import { MEDIUM_SPACING } from 'constants'
import { StyledImg, StyledModal } from 'components/basic-styled-components'

const Container = styled(Grid)`
  margin-bottom: ${LARGE_SPACING};
`

const LeftItem = styled(Grid)`
  padding-right: ${LARGE_SPACING};
  border-radius: 20px;
`

const RightItem = styled(Grid)`
  padding-left: ${LARGE_SPACING};
`

const TitleContainer = styled(Grid)`
  padding-bottom: ${MEDIUM_SPACING};
`

const DateCreatedBlock = styled(Typography)`
  padding-left: ${MEDIUM_SPACING};
`

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${theme.typography.allVariants.color};
  text-decoration-color: ${theme.typography.allVariants.color};
  font-weight: bold;
`

const ReadMoreContainer = styled.div`
  float: right;
  margin-top: ${MEDIUM_SPACING}
`

const BlogPost = ({ postDetails, isAuth }) => {
  const {
    id,
    url,
    author,
    firstParagraphText,
    title,
    mainPhotoPath,
    dateCreated,
  } = postDetails

  const storage = getStorage()
  const navigate = useNavigate()

  const [imgUrl, setImgUrl] = useState(null)
  const [showDeletePostDialog, setShowDeletePostDialog] = useState(false)
  const linkToBlog = `/blog/${url}`

  if (mainPhotoPath) {
    const pathReference = ref(storage, mainPhotoPath)
    getDownloadURL(pathReference).then((url) => {
      setImgUrl(url)
    })
  }

  const deletePost = async (id) => {
    const postDoc = doc(db, 'posts', id)
    await deletePhoto()
    await deleteDoc(postDoc)
  }

  const deletePhoto = () => {
    let photoPathRef = null
    if (mainPhotoPath) {
      photoPathRef = ref(storage, mainPhotoPath)
    }
  
    deleteObject(photoPathRef).then((res) => {
      console.log('Successfully Deleted: ', res)
    }).catch((err) => {
      console.log('Error deleting photo: ', err)
    });

  }

  const navigateToFullBlog = () => {
    navigate(linkToBlog)
  }

  return (
    <>
      <Container container>
        <LeftItem item xs={6}>
          {imgUrl ? (
            <StyledImg
              src={imgUrl}
              height={300}
              onClick={() => navigateToFullBlog()}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <Skeleton
              height={300}
              borderRadius={20}
            />
          )}
        </LeftItem>
        <RightItem item xs={6}>
          <TitleContainer container alignItems='center'>
            <Grid item xs>
              <StyledLink
                variant='h3'
                display='inline'
                underline='hover'
                onClick={() => navigateToFullBlog()}
              >
                {title}
              </StyledLink>
              {dateCreated && (
                <DateCreatedBlock display='inline'>
                  {dayjs(dateCreated).format('MMMM D, YYYY')}
                </DateCreatedBlock>
              )}
            </Grid>
            <Grid item>
              {isAuth && author.id === auth.currentUser.uid && (
                <IconButton onClick={() => {
                  setShowDeletePostDialog(true)
                }}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </TitleContainer>
          <Typography>
            {firstParagraphText}
          </Typography>
          <ReadMoreContainer>
            <StyledLink
              variant='body1'
              display='inline'
              underline='always'
              onClick={() => navigateToFullBlog()}
            >
              Read More...
            </StyledLink>
          </ReadMoreContainer>
        </RightItem>
      </Container>
      {isAuth && showDeletePostDialog && (
        <StyledModal
          open={showDeletePostDialog}
          title='Delete Post'
          text={`Are you sure you'd like to delete this post?`}
          onClose={() => {
            setShowDeletePostDialog(false)
          }}
          onAccept={() => {
            deletePost(id)
            // TODO: Delete image associated with post as well
            setShowDeletePostDialog(false)
          }}
        />
      )}
    </>
  )
}

export default BlogPost
