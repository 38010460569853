// import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import postsReducer from '../reducers/postsSlice'
import layoutReducer from '../reducers/layoutSlice'

export default combineReducers({
  // firebase: firebaseReducer,
  firestore: firestoreReducer,
  posts: postsReducer,
  layout: layoutReducer,
})