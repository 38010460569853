import React from 'react'
import styled from '@emotion/styled'
import theme from 'theme'

import { Button, Grid, Link, Typography, } from '@mui/material'
import { LARGE_SPACING, MEDIUM_SPACING } from 'constants'
import { StyledImg } from 'components/basic-styled-components'
// import introPic from './static-beer-pic.jpg'
import { MAX_SCREEN_WIDTH_WIDE } from 'constants'
import BigBlog from './BigBlog'
import SideBlog from './SideBlog'
import { useSelector } from 'react-redux'

const Container = styled(Grid)`
  margin-bottom: ${LARGE_SPACING};
  padding-right: ${LARGE_SPACING};
  padding-left: ${LARGE_SPACING};
`

const InnerContainer = styled(Grid)`
  max-width: ${MAX_SCREEN_WIDTH_WIDE};
  min-height: 50vh;
`

const LeftItem = styled(Grid)`
  height: 100%;
  padding-right: ${LARGE_SPACING};
`

const RightItem = styled(Grid)`
  height: 100%;
  // padding-left: ${LARGE_SPACING};
`

const HeaderIntro = () => {
  const { posts } = useSelector((state) => {
    return {
      posts: state.posts.allPosts,
    }
  })

  const featuredBlog1 = posts['HP4WTJguBWra9v87dPMT']
  const featuredBlog2 = posts['HX5tltAfZaIHFo4h6WWS']
  const featuredBlog3 = posts['Irh4UCQF5jseM6qysJaa']

  return (
    <Container container justifyContent='center'>
      <InnerContainer container alignItems='center'>
        <LeftItem item xs={8}>
          <BigBlog blogDetails={featuredBlog1} />
        </LeftItem>
        <RightItem item xs={4}>
          <Grid container direction='column' sx={{ height: '100%' }}>
            <Grid item xs={6} sx={{ paddingBottom: MEDIUM_SPACING }}>
              <SideBlog
                background='linear-gradient(to bottom right, #5350FF, #9D50FF)'
                variant='light'
                blogDetails={featuredBlog2}
              />
            </Grid>
            <Grid item xs={6} sx={{ paddingTop: MEDIUM_SPACING }}>
              <SideBlog
                background='linear-gradient(to bottom right, #FFCA7A, #FFA27A)'
                variant='dark'
                blogDetails={featuredBlog3}
              /> 
            </Grid>
          </Grid>
        </RightItem>
      </InnerContainer>
    </Container>
  )
}

export default HeaderIntro
