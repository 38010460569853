import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// Firebase imports
import { addDoc, collection } from 'firebase/firestore'
import { db } from 'firebase-config'

// Mui imports
import { styled as MuiStyled } from '@mui/material/styles';
import styled from '@emotion/styled'
import { Grid, Link, TextField, Typography, Button } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
// File imports
import theme from 'theme'
import { MEDIUM_SPACING, SMALL_SPACING, HUGE_SPACING, LARGE_SPACING } from 'constants'
import PageContainer from 'components-layout/PageContainer'
import { CustomHorizontalRule } from './basic-styled-components'
import dayjs from 'dayjs';

const Container = styled.div`
  background-color: ${theme.palette.neutral.light};
  margin-top:  ${LARGE_SPACING};
  padding-top:  ${HUGE_SPACING};
  padding-bottom:  ${HUGE_SPACING};
  width: 100%;
`

const FooterItem = styled(Grid)`
  margin-bottom: ${SMALL_SPACING};
`

const Header = styled(Typography)`
  font-weight: bold;
`

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${theme.typography.allVariants.color};
  text-decoration-color: ${theme.typography.allVariants.color};
  font-weight: bold;
`

const IconContainer = styled(Grid)`
  padding-right: 10px;
`

const EmailSignupComponent = styled.div`
  height: 56px;
`

const EmailSignupButton = styled(Button)`
  background-image: linear-gradient(to bottom right, #5350FF, #9D50FF);
  border-radius: 0 20px 20px 0;
  height: 100%;
  width: 30%;
  text-transform: none;
  font-size: 1rem;
`

const EmailSignupTextField = MuiStyled(TextField)({
  width: '70%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '20px 0 0 20px',
    },
    '&:hover fieldset': {
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderWidth: 1,
    },
  },
  // '& label.Mui-focused': {
  // },
  // '& .MuiInput-underline:after': {
  // },
})

const quickLinks = [{
  label: 'Home',
  link: '/',
}, {
  label: 'Blog',
  link: '/blog',
}, {
  label: 'Latest Post',
  link: '/blog/what-to-do-when-you-break-a-bone-while-abroad',
}]

const contactLinks = [
  // {
  //   label: 'Send me an email!',
  //   link: '/',
  //   icon: <EmailIcon />
  // },
  {
    label: 'Follow me here!',
    link: 'https://www.instagram.com/ericsremote',
    type: 'external',
    icon: <InstagramIcon />,
  }
]

const EmailSignup = ({value, onChange, onSubmit, error}) => {
  return (
    <EmailSignupComponent>
      <EmailSignupTextField
        label='Email'
        value={value}
        onChange={onChange}
        error={error}
        helperText={error && 'Please enter a valid email'}
      />
      <EmailSignupButton
        variant='contained'
        disableElevation
        onClick={onSubmit}
      >
        Hell Yeah!
      </EmailSignupButton>
    </EmailSignupComponent>
  )
}

const FooterColumn = ({ title, links, navigate }) => {
  return (
    <>
      <Header>{title}</Header>
      <CustomHorizontalRule spacing={MEDIUM_SPACING} />
      {links && links.map((link, i) => {
        return (
          <FooterItem item xs key={i}>
            <StyledLink
              underline='hover'
              onClick={() => {
                navigate(link.link)
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              href={link.type && link.type === 'external' && link.link}
              target={link.type && link.type === 'external' && '_blank'}
            >
              <Grid container>
                {link.icon && (
                  <IconContainer item>
                    {link.icon}
                  </IconContainer>
                )}
                <Grid item>
                  <Typography>
                    {link.label}
                  </Typography>
                </Grid>
              </Grid>
            </StyledLink>
          </FooterItem>
        )
      })}
    </>
  )
}

const Footer = () => {
  const emailListCollectionsRef = collection(db, 'emailList')
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const isValidEmail = (e) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e)
  }

  const onEmailSubmit = () => {
    if (isValidEmail(email)) {
      // TODO: Add email to DB here
      console.log('valid: ', email)
      addDoc(emailListCollectionsRef, {
        email,
        dateSubscribed: dayjs().format(),
      })
      setEmailError(false)
      setEmailSent(true)
    } else {
      setEmailError(true)
    }
  }

  return (
    <Container>
      <PageContainer>
        <Grid container justifyContent='space-between' spacing={6}>
          <Grid item xs={3}>
            <FooterColumn title='Quick Links' links={quickLinks} navigate={navigate} />
          </Grid>
          <Grid item xs={3}>
            <FooterColumn title='Contact' links={contactLinks} />
          </Grid>
          <Grid item xs={6}>
            <Header>Want to be notified when I post?</Header>
            <CustomHorizontalRule spacing={MEDIUM_SPACING} />
            {emailSent ? (
              <Typography>
                Thanks for signing up! You&apos;ll be notified the next time I post.
              </Typography>
            ) : (
              <EmailSignup
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setEmailError(false)
                }}
                onSubmit={onEmailSubmit}
                error={emailError}
              />
            )}
          </Grid>
        </Grid>
      </PageContainer>
    </Container>
  )
}

export default Footer
