import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import dayjs from 'dayjs'
import 'react-loading-skeleton/dist/skeleton.css'
// Firebase imports
import { db } from 'firebase-config'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { deleteDoc, doc } from 'firebase/firestore'
// Mui imports
import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
// File imports
import { SMALL_SPACING, LARGE_SPACING, MEDIUM_SPACING } from 'constants'
import { StyledImg, StyledModal } from 'components/basic-styled-components'
import { CustomHorizontalRule } from 'components/basic-styled-components'

const ContainerWithBottomMargin = styled.div`
  margin-bottom: ${LARGE_SPACING};
`

const Title = styled(Typography)`
  font-weight: bold;
`

const Header = styled(Typography)`
  font-weight: bold;
`

const Subheader = styled(Typography)`
  font-weight: 500;
`

const ParagraphTypography = styled(Typography)`
  line-height: 2;
`

const DateGrid = styled(Grid)`
  padding-top: ${MEDIUM_SPACING};
`

const DateContainer = styled.div`
  background-image: linear-gradient(to bottom right, #5350FF, #9D50FF);
  display: inline-block;
  padding: ${SMALL_SPACING} ${MEDIUM_SPACING};
  border-radius: 20px;
  float: right;
  color: white;
`

const BlogPost = ({ postDetails, isAuth }) => {
  const {
    id,
    author,
    firstParagraphText,
    postSections,
    title,
    mainPhotoPath,
    dateCreated,
  } = postDetails

  const storage = getStorage()

  const [imgUrl, setImgUrl] = useState(null)
  const [showDeletePostDialog, setShowDeletePostDialog] = useState(false)

  if (mainPhotoPath) {
    const pathReference = ref(storage, mainPhotoPath)
    getDownloadURL(pathReference).then((url) => {
      setImgUrl(url)
    })
  }

  const deletePost = async (id) => {
    const postDoc = doc(db, 'posts', id)
    await deleteDoc(postDoc)
  }

  return (
    <>
      <Grid container alignItems='flex-end'>
        <Grid item xs={12}>
          <Title variant='h1'>
            {title}
          </Title>
        </Grid>
        <DateGrid item xs={12}>
          <DateContainer>
            <Typography variant='body1' color='white'>
              {dayjs(dateCreated).format('MMMM D, YYYY')}
            </Typography>
          </DateContainer>
        </DateGrid>
      </Grid>
      <CustomHorizontalRule />
      {/* Top image section */}
      <ContainerWithBottomMargin>
        {imgUrl ? (
          <StyledImg src={imgUrl} height={500} />
        ) : (
          <Skeleton
            height={300}
            borderRadius={20}
          />
        )}
      </ContainerWithBottomMargin>
      <ContainerWithBottomMargin>
        <ParagraphTypography>
          {firstParagraphText}
        </ParagraphTypography>
      </ContainerWithBottomMargin>
      {postSections.map((section, i) => {
        if (section.type === 'paragraph') {
          return (
            <ContainerWithBottomMargin key={i}>
              <ParagraphTypography>
                {section.value}
              </ParagraphTypography>
            </ContainerWithBottomMargin>
          )
        }
        if (section.type === 'header') {
          return (
            <ContainerWithBottomMargin key={i}>
              <Header variant='h2'>
                {section.value}
              </Header>
            </ContainerWithBottomMargin>
          )
        }
        if (section.type === 'subheader') {
          return (
            <ContainerWithBottomMargin key={i}>
              <Subheader variant='h3'>
                {section.value}
              </Subheader>
            </ContainerWithBottomMargin>
          )
        }
      })}
      {/* <TitleContainer container alignItems='center'>
        <Grid item xs>
          {dateCreated && (
            <DateCreatedBlock display='inline'>
              {dayjs(dateCreated).format('MMMM D, YYYY')}
            </DateCreatedBlock>
          )}
        </Grid>
        <Grid item>
          {isAuth && author.id === auth.currentUser.uid && (
            <IconButton onClick={() => {
              setShowDeletePostDialog(true)
            }}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </TitleContainer> */}
      {isAuth && (
        <StyledModal
          open={showDeletePostDialog}
          title='Delete Post'
          text={`Are you sure you'd like to delete this post?`}
          onClose={() => {
            setShowDeletePostDialog(false)
          }}
          onAccept={() => {
            deletePost(id)
            setShowDeletePostDialog(false)
          }}
        />
      )}
    </>
  )
}

export default BlogPost
