import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateSelectedPage } from 'redux/reducers/layoutSlice'

import BlogPost from 'components/BlogPost'
import HeaderIntro from 'components/HeaderIntro'
import Footer from 'components/Footer'
import Loading from 'components/BlogPost/Loading'

import PageContainer from 'components-layout/PageContainer'
import TitleContainer from 'components-layout/TitleContainer'

const Home = ({ isAuth }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSelectedPage({
      name: 'home',
      index: 0,
    }))
  }, [])

  const { posts } = useSelector((state) => {
    return {
      posts: state.posts.allPosts,
    }
  })

  return (
    <>
      <HeaderIntro />
      <PageContainer>
        <TitleContainer align='center'>
          Blog Posts
        </TitleContainer>
        {Object.keys(posts).length === 0 && (
          <Loading />
        )}
        {Object.keys(posts).map((postKey) => {
          return (
            <BlogPost
              postDetails={posts[postKey]}
              isAuth={isAuth}
              key={postKey}
            />
          )
        })}
      </PageContainer>
      <Footer />
    </>
  );
}

export default Home
